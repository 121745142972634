import revive_payload_client_4sVQNw7RlN from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/maverixbroadband.com/mx-front/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/maverixbroadband.com/mx-front/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/maverixbroadband.com/mx-front/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KlVwwuJRCL from "/home/maverixbroadband.com/mx-front/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/home/maverixbroadband.com/mx-front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/maverixbroadband.com/mx-front/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import templates_pwa_client_287281b3_rWmc6dGXww from "/home/maverixbroadband.com/mx-front/.nuxt/templates.pwa.client.287281b3.ts";
import plugin_w2VlvAEcpa from "/home/maverixbroadband.com/mx-front/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import animateOnScroll_C9LsKpZhSC from "/home/maverixbroadband.com/mx-front/plugins/animateOnScroll.js";
import vue_gtm_client_Cm24Dsc5BN from "/home/maverixbroadband.com/mx-front/plugins/vue-gtm.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_KlVwwuJRCL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  templates_pwa_client_287281b3_rWmc6dGXww,
  plugin_w2VlvAEcpa,
  animateOnScroll_C9LsKpZhSC,
  vue_gtm_client_Cm24Dsc5BN
]