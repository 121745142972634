<template>
	<NuxtLayout>
		<VitePwaManifest
			v-if="!useCustomManifest"
		/>
		<NuxtPage />
		<Toast />
	</NuxtLayout>
</template>

<script setup>

import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

const route = useRoute()

const useCustomManifest = computed(() => !!route.meta?.useCustomManifest)

const { locale } = useI18n()

useHead({
	htmlAttrs: [
		{
			lang: () => {
				return locale.value
			}
		}
	]
})

</script>
