<template>
	<div class="flex flex-col h-screen pt-12 md:pt-0">
		<div class="grow">
			<!--
			<TheHeader
				:is-logged-in="isLoggedIn"
				@click="navigateAndClearError()"
			/>
			-->
			<div class="fixed top-0 z-30 w-full bg-mx-navy md:hero-image hero-lengths">
				<section class="container px-4 mx-auto md:py-4">
					<div class="flex justify-end h-16 px-4 whitespace-nowrap md:justify-between">
						<div class="flex w-full">
							<NavLogo is-error-page />
						</div>
					</div>
				</section>
			</div>
			<main class="grid min-h-full px-6 py-24 bg-gray-100 place-items-center sm:py-32 lg:px-8">
				<div class="text-center">
					<p class="text-base font-semibold text-mx-orange">
						404
					</p>
					<h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
						Page not found
					</h1>
					<p class="mt-6 text-base leading-7 text-gray-600">
						Sorry, we couldn’t find the page you’re looking for.
					</p>
					<div class="flex items-center justify-center mt-10 gap-x-6">
						<button
							class="rounded-md bg-mx-orange px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition-all duration-100 ease-in-out hover:bg-mx-orange-muted focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mx-orange"
							@click="navigateAndClearError('/')"
						>
							Go back home
						</button>
						<button
							class="text-sm font-semibold text-gray-900 transition-all duration-100 ease-in-out hover:underline decoration-2 underline-offset-2 decoration-mx-orange"
							@click="navigateAndClearError('/contact')"
						>
							Contact support <span aria-hidden="true">
								&rarr;
							</span>
						</button>
					</div>
				</div>
			</main>
		</div>
		<!--
		<TheFooter
			class="sticky w-full top-full"
			:is-logged-in="isLoggedIn"
			@click="navigateAndClearError()"
		/>
		-->
	</div>
</template>

<script setup lang="ts">
/*
const userCookie = useCookie('uu')

const isLoggedIn = computed(() => {
	return !!userCookie.value
})
*/

const navigateAndClearError = (path?: string | undefined) => {
	if (path?.length) {
		clearError({ redirect: path })
	} else {
		clearError()
	}
}
</script>
