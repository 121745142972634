// https://nuxt.com/docs/guide/going-further/custom-routing#using-approuteroptions
// https://router.vuejs.org/api/interfaces/RouterOptions.html
// NOTE: This is workaround while waiting for native scrollBehavior support in Nuxt 3
// https://nuxt.com/docs/migration/component-options#scrolltotop
// https://nuxt.com/docs/guide/directory-structure/pages/#router-options
export default {
	scrollBehavior () { // always scroll to top when changing route
		return { top: 0 }
	}
}
