<template>
	<div
		:class="[
			'-mr-2 lg:mr-0 absolute items-center flex-shrink-0 transform -translate-x-1/2 md:flex md:relative md:transform-none left-1/2 md:top-auto md:left-auto md:translate-x-0 md:translate-y-0',
			position
		]"
	>
		<button
			class="rounded-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-offset-mx-navy focus-visible:ring-mx-orange focus-visible:ring-opacity-80"
			@click="
				async () => {
					handleClose();
				}
			"
		>
			<TheLogo white class="w-48 h-8 lg:h-12" />
		</button>
	</div>
</template>

<script setup lang="ts">
const props = defineProps({
	showAlertBanner: {
		type: Boolean,
		default: false
	},
	isErrorPage: {
		type: Boolean,
		default: false
	}
})

const { showAlertBanner, isErrorPage } = toRefs(props)

const position = computed(() => {
	// Mobile: 96px = 16px (header) + 80px (banner)
	return showAlertBanner.value ? 'top-[96px]' : 'top-[16px]'
})

const emit = defineEmits([ 'close' ])

const localePath = useLocalePath()

const handleClose = () => {
	if (isErrorPage.value) {
		clearError({ redirect: localePath('/') })
	} else {
		navigateTo(localePath('/'))
	}
	emit('close')
}
</script>
