import { createGtm } from '@gtm-support/vue-gtm'
import { GOOGLE_TAG_MANAGER_ID, GTM_DEBUG } from '@/constants/index.js'

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(createGtm({
		id: GOOGLE_TAG_MANAGER_ID,
		defer: true,
		compatibility: true,
		source: 'https://www.googletagmanager.com/gtm.js',
		debug: GTM_DEBUG,
		vueRouter: useRouter()
	}))
})
