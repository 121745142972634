<template>
	<NuxtImg
		:src="logo"
		alt="Maverix Broadband"
		width="300"
		height="73"
		:loading="loading"
	/>
</template>

<script setup lang="ts">
const props = defineProps({
	white: {
		type: Boolean,
		default: false
	},
	lazyLoad: {
		type: Boolean,
		default: false
	}
})

const { white, lazyLoad } = toRefs(props)

const logo = computed(() => {
	return white.value ? '/images/logos/maverix-broadband-white.svg' : '/images/logos/maverix-broadband.svg'
})

const loading = computed(() => {
	return lazyLoad.value ? 'lazy' : undefined
})
</script>
