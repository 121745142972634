export const PRODUCTION = 'production'
export const DEVELOPMENT = 'development'
export const STAGING = 'staging'
export const RELEASE_STAGE = import.meta.env.VITE_RELEASE_STAGE || import.meta.env.NODE_ENV || PRODUCTION
export const IS_DEVELOPMENT = RELEASE_STAGE === DEVELOPMENT
export const IS_PRODUCTION = RELEASE_STAGE === PRODUCTION
export const CUSTOM_API_URL = import.meta.env.VITE_API_URL || ''

export const GOOGLE_TAG_MANAGER_ID = 'GTM-WX8QD3C'
export const GTM_DEBUG = !IS_PRODUCTION
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBKSU_hEcFsvKNi9yRkfFj-o65oE02BQ4w'

export const PREVIEW_STORYBLOK_KEY = 'm1sCj9JeparJQSzvrdrmNwtt'
export const PUBLIC_STORYBLOK_KEY = '530DTwxIFrmjwRtjzC2mVwtt'
export const STORYBLOK_KEY = !IS_DEVELOPMENT ? PUBLIC_STORYBLOK_KEY : PREVIEW_STORYBLOK_KEY
export const STORYBLOK_VERSION = !IS_DEVELOPMENT ? 'published' : 'draft'

export const COMPANY_NAME = 'Maverix Broadband'
export const COMPANY_PHONE = '+1-720-740-0888'

export const SOCIAL_FACEBOOK_URL = 'https://www.facebook.com/maverixBB/'
export const SOCIAL_GOOGLE_URL = 'https://www.google.com/maps/place/Maverix+Broadband/@39.4997395,-104.710539,11z/data=!4m6!3m5!1s0x6efa953aab4d9d79:0xf16f6e005df2538!8m2!3d39.4997395!4d-104.710539!16s%2Fg%2F11sxyfbj_4?sa=X&ved=2ahUKEwiHhJfJkIT-AhVCOH0KHfGbBMYQ_BJ6BQiqARAI&coh=164777&entry=tt&shorturl=1'
export const SOCIAL_NEXTDOOR_URL = 'https://nextdoor.com/pages/maverix-broadband-parker-co/'

export const BASE_URL = import.meta.env.VITE_BASE_URL || 'https://maverixbroadband.com'

const accountUrls = {
	[PRODUCTION]: 'https://account.maverixbroadband.com',
	[STAGING]: 'https://staging-mx-account.maverixbroadband.com',
	[DEVELOPMENT]: 'https://dev-mx-account.maverixbroadband.com'
}

const portalUrls = {
	[PRODUCTION]: 'https://portal.maverixbroadband.com/',
	[STAGING]: 'https://staging-mx-portal.maverixbroadband.com/',
	[DEVELOPMENT]: 'https://dev-mx-portal.maverixbroadband.com/'
}

export const ACCOUNT_URL = accountUrls[RELEASE_STAGE] || accountUrls[PRODUCTION]
export const PORTAL_URL = portalUrls[RELEASE_STAGE] || portalUrls[PRODUCTION]

const apiUrls = {
	[PRODUCTION]: 'https://api.maverixbroadband.com',
	[STAGING]: 'https://staging-mx-back.maverixbroadband.com',
	[DEVELOPMENT]: 'https://dev-mx-back.maverixbroadband.com'
}

export const API_BASE = CUSTOM_API_URL || apiUrls[RELEASE_STAGE]
export const API_ENDPOINT_NON_BUSINESS_CONTACT = '/contact/nonBusiness'
export const API_ENDPOINT_BUSINESS_CONTACT = '/contact/business'
export const API_ENDPOINT_ALERTS = '/alerts'
export const API_ENDPOINT_SIGNUP = '/prospect/signup'
export const API_ENDPOINT_ADDRESS = '/prospect/address'
export const API_ENDPOINT_EVENTS = '/portal/kiosk/events'

export const LEGACY_CONTACT_API_ENDPOINT = '/contact.php?json=true'
export const LEGACY_FIBER_API_ENDPOINT = '/fiber.php?json=true'
export const LEGACY_LOGIN_ENDPOINT = '/login.php?json=true'
export const LEGAL_NAME = 'Maverix Broadband, Inc.'

export const SHARED_COOKIE_DOMAIN = '.maverixbroadband.com'

export default {
	PRODUCTION,
	DEVELOPMENT,
	RELEASE_STAGE,
	IS_PRODUCTION,
	GOOGLE_TAG_MANAGER_ID,
	GTM_DEBUG,
	LEGACY_CONTACT_API_ENDPOINT,
	COMPANY_NAME,
	COMPANY_PHONE,
	BASE_URL,
	GOOGLE_MAPS_API_KEY,
	LEGACY_LOGIN_ENDPOINT,
	API_BASE,
	API_ENDPOINT_NON_BUSINESS_CONTACT,
	API_ENDPOINT_BUSINESS_CONTACT,
	API_ENDPOINT_ALERTS,
	API_ENDPOINT_SIGNUP,
	API_ENDPOINT_ADDRESS,
	API_ENDPOINT_EVENTS,
	STORYBLOK_KEY,
	STORYBLOK_VERSION,
	SOCIAL_FACEBOOK_URL,
	SOCIAL_GOOGLE_URL,
	SOCIAL_NEXTDOOR_URL,
	ACCOUNT_URL,
	LEGAL_NAME
}
