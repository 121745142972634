<template>
	<div
		aria-live="assertive"
		class="fixed inset-0 z-50 flex items-start px-4 py-6 pointer-events-none sm:p-6"
	>
		<div class="flex flex-col w-full space-y-2">
			<TransitionGroup name="toast">
				<div
					v-for="toast in toasts"
					:key="toast.id"
					class="flex flex-col items-center w-full space-y-4 sm:items-end"
				>
					<div class="w-full max-w-sm overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
						<div class="p-4">
							<div class="flex items-start">
								<div class="flex-shrink-0">
									<CheckCircleIcon
										v-if="toast.notificationType === 'success'"
										:id="`toast-success-icon-${toast.id}`"
										class="w-6 h-6 text-green-400"
										aria-hidden="true"
									/>
									<XCircleIcon
										v-else
										:id="`toast-error-icon-${toast.id}`"
										class="w-6 h-6 text-red-400"
										aria-hidden="true"
									/>
								</div>
								<div class="ml-3 w-0 flex-1 pt-0.5">
									<p
										:id="`toast-title-${toast.id}`"
										class="text-sm font-medium text-gray-900"
									>
										{{ toast.title }}
									</p>
									<p
										:id="`toast-message-${toast.id}`"
										class="mt-1 text-sm text-gray-500"
									>
										{{ toast.message }}
									</p>
								</div>
								<div class="flex flex-shrink-0 ml-4">
									<button
										type="button"
										class="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-mx-orange focus:ring-offset-2"
										@click="removeToast(toast.id)"
									>
										<span class="sr-only">
											Close
										</span>
										<XMarkIcon
											class="w-5 h-5"
											aria-hidden="true"
										/>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</TransitionGroup>
		</div>
	</div>
</template>

<script setup lang="ts">
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { XMarkIcon } from '@heroicons/vue/20/solid'

import { useToast } from '@/composables/useToast'
const { toasts, removeToast } = useToast()

</script>
