let observer = null

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('animateOnScroll', {
		beforeMount (el, binding) {
			const options = binding.value || {}
			observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						// wrap the element with an element with overflow hidden to prevent layout shift
						// check if we haven't already created the wrapper by checking if the parent element has the class 'animateOnScroll-wrapper'
						if (!el.parentElement.classList.contains('animateOnScroll-wrapper')) {
							// create wrapper element
							const wrapperElement = document.createElement('div')
							// set wrapper element styles
							// prevent overflow
							wrapperElement.style.overflow = 'hidden'
							// set position to relative
							wrapperElement.style.position = 'relative'
							// set wrapper height and width to element height and width
							wrapperElement.style.height = `${el.offsetHeight}px`
							wrapperElement.style.width = `${el.offsetWidth}px`
							// add class to wrapper element for our check on line 13 to see if we've already created it
							wrapperElement.classList.add('animateOnScroll-wrapper')
							// insert wrapper before element
							el.parentNode.insertBefore(wrapperElement, el)
							// move element into wrapper element
							wrapperElement.appendChild(el)
							// add directive class to element so the component can detect that it's been animated
							el.classList.add('animateOnScroll')
							// add animation class to element
							el.classList.add(options.animation || 'fadeInUp')
							// remove wrapper overflow hidden after animation is finished to allow absolute positioning in child elements that exceed the wrapper bounds
							setTimeout(() => {
								el.parentElement.style.overflow = 'visible'
							}, 500)
						}
					}
				})
			})
			// observe element
			observer.observe(el)
		},
		beforeUnmount (el) {
			// disconnect the observer
			if (observer) {
				observer.unobserve(el)
				observer.disconnect()
			}
		},
		getSSRProps (_binding, _vnode) {
			// server-side implementation:
			// you can provide SSR-specific props here
			// return the props to be rendered.
			// getSSRProps only receives the directive binding.
			return {}
		}
	})
})
